import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { API_METHOD, API_URLS } from '../constants';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly loaderService: LoaderService,
  ) {}

  public invoke(
    params,
    showLoader = false,
    isDitaApi = false,
    isNoPrefix = false,
  ): Observable<any> {
    showLoader && this.loaderService.start();
    const method = params.method.toLowerCase();
    const { path, body, query } = params;
    let url = `${environment.apiUrl}`;
    if (!isNoPrefix) {
      url = !isDitaApi
        ? `${environment.apiUrl}${API_URLS.apiPrefix}/`
        : `${environment.apiUrl}${API_URLS.ditaPrefix}/`;
    }
    const requestURL = `${url}${path}/`;
    console.log(requestURL)
    let requestParams = new HttpParams();
    /**
     * CUSTOM REQUEST QUERY (?key=value)
     */
    if (query) {
      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          requestParams = requestParams.append(key, query[key]);
        }
      }
    }
    const requestOptions = {
      params: requestParams,
    };
    /**
     * RETURN API REQUEST
     */
    console.log(requestURL)
    return this.makeRequest(method, requestURL, requestOptions, body);
  }

  public analytics(
    params,
    showLoader = false,
    isDitaApi = false,
    isNoPrefix = false,
  ): Observable<any> {
    showLoader && this.loaderService.start();
    const method = params.method.toLowerCase();
    const { path, body, query } = params;
    let url = `${environment.apiUrl}`;
    if (!isNoPrefix) {
      url = !isDitaApi
        ? `${environment.apiUrl}`: `${environment.apiUrl}`;
    }
    const requestURL = `${url}${path}/`;
    let requestParams = new HttpParams();
    /**
     * CUSTOM REQUEST QUERY (?key=value)
     */
    if (query) {
      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          requestParams = requestParams.append(key, query[key]);
        }
      }
    }
    const requestOptions = {
      params: requestParams,
    };
    /**
     * RETURN API REQUEST
     */
    return this.makeRequest(method, requestURL, requestOptions, body);
  }
  private makeRequest(method, requestURL, requestOptions, body) {
    let request;
    /**
     * HANDLE GET, POST etc. REQUESTS
     */
    if (method === API_METHOD.get) {
      request = this.http[method](requestURL, requestOptions);
    } else if (
      method === API_METHOD.post ||
      method === API_METHOD.put ||
      method === API_METHOD.patch
    ) {
      request = this.http[method](requestURL, body, requestOptions);
    } else if (method === API_METHOD.delete) {
      request = this.http.request(method, requestURL, {
        ...requestOptions,
        body,
      });
    }
    return request;
  }
}