import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ROUTE_PATHS } from "./core/constants";
import { LoginHelperService } from "./core/services/login-helper.service";
import { VersionCheckService } from "./core/services/version-check.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {

  constructor(private router: Router, private helper: LoginHelperService, private versionCheckService: VersionCheckService) { 
    this.helper.channel.onmessage = (event: any) => {
      let data = event.data;
      if (typeof data == "string" && data.startsWith("LOGOUT")) {
        let message: Array<string> = data.split("-");
        this.redirectToLogin(message.length > 1 ? message[1] : null);
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      }
    }
  }

  ngOnInit(): void {
    this.versionCheckService.initVersionCheck('fe_assets/version.json');
  }
  
  redirectToLogin(whiteLabel: string | null = null): void {
    let url = "/" + ROUTE_PATHS.xe_login;
    if (whiteLabel)
      url += ("/" + whiteLabel)
    
    this.router.navigate([url]);
  }
}
