export const DOCUMENT_TYPE = {
  none: 0,
  html: 1,
  xbrl: 2,
  ixbrl: 3,
  iXBRL_Review: 11,
  xbrlWithEdgar: 4,
  nonXbrl: 5,
  ferc: 10,
  wordML: 6,
  xmlForm: 12,
  pdf:13
};
export const DOC_PROCESS_TYPE = {
  rendering: "1",
  commentTransfer: "2",
  chunking: "3",
};

export const DITA_RESOURCE_TYPE = {
  topic: "Topic",
  map: "Map",
};
export const MONTH_INDEX = {
  jan: 0,
  feb: 1,
  march: 2,
  april: 3,
  may: 4,
  jun: 5,
  jul: 6,
  aug: 7,
  sept: 8,
  oct: 9,
  nov: 10,
  dec: 11,
};
export const MUTUAL_FUND_FORMS = [
  "485BPOS",
  "497",
  "485APOS",
  "485BXT",
  "N-1A",
  "N-1A/A",
];

export const IFRAME_TYPE = {
  contentPane: "content_pane",
};

export const DOCUMENT_TYPE_LABEL = [
  { type: DOCUMENT_TYPE.xbrl, label: "XBRL" },
  { type: DOCUMENT_TYPE.nonXbrl, label: "Non XBRL" },
  // { type: DOCUMENT_TYPE.iXBRL_Review, label: "iXBRL" },
  { type: DOCUMENT_TYPE.xmlForm, label: "XML Form" },
  { type: DOCUMENT_TYPE.wordML, label: "Word"},
];

export const DOCUMENT_LIST =
[
  { type: DOCUMENT_TYPE.html, label:"HTML" },
  {type: DOCUMENT_TYPE.pdf,label:"PDF"},
  {type: DOCUMENT_TYPE.ixbrl,label:"IXBRL"}
];
export const PUBLISH_TYPE = {
  pdf: "pdf",
  html: "html",
  ixbrl: "ixbrl",
  word: "word",
};

export const XBRL_REPORT_TYPE = {
  context: 0,
  unit: 1,
  negation: 2,
  extended: 3,
  footnote: 4,
  concept: 14,
  fact: 15,
  presentation: 19,
};

export const SEC_Files = {
  roleJson: "roles.json",
};

export const SEC_TYPE = {
  element: "element",
  fact: "fact",
  role: "role",
};

export const XBRL_ROLE_LABEL = {
  mStandardLabel: "http://www.xbrl.org/2003/role/label",
  mDocumentationLabel: "http://www.xbrl.org/2003/role/documentation",
  mDeprecatedLabel: "http://www.xbrl.org/2009/role/deprecatedLabel",
  mDeprecatedDateLabel: "http://www.xbrl.org/2009/role/deprecatedDateLabel",
  mTotalLabel: "http://www.xbrl.org/2003/role/totalLabel",
};
export const DOCUMENT_TYPE_LABEL_FOR_LIB = [
  { type: DOCUMENT_TYPE.xbrl, label: "XBRL" },
  { type: DOCUMENT_TYPE.nonXbrl, label: "Non XBRL" },
];

export const EA_DOCUMENT_TYPE =
{
  HTML:1,
  PDF:2,
  OTHERS:3,
  ADAPDF:4,
  IXBRL:5
}

export const WHITE_LABEL_COMPANY_LIST = {
  "xmledge": "XML_EDGE",
  "lieber&co": "LIBERTS_AND_CO",
  "BOI_Agent": "BOI_AGENT",
  "417afs": "AFS"
}

export const COMPANY_LOGO_LIST = {
  "XML_EDGE": "/fe_assets/images/logos/ea_white.png",
  "BOI_AGENT": "/fe_assets/images/logos/boi_agent.jpg",
  "LIBERTS_AND_CO": "/fe_assets/images/logos/lieber_and_co.png",
  "AFS": "/fe_assets/images/logos/afs.png",
  "XML_EDGE_MIN": "/fe_assets/images/logos/ea_Icon_white.png",
  "BOI_AGENT_MIN": "/fe_assets/images/logos/boi_agent_min.jpg",
  "LIBERTS_AND_CO_MIN": "/fe_assets/images/logos/lieber_and_co_min.png",
  "AFS_MIN": "/fe_assets/images/logos/afs_min.png",
  "XML_EDGE_AUTH": "/fe_assets/images/logos/ea_black.png",
  "BOI_AGENT_AUTH": "/fe_assets/images/logos/boi_agent.jpg",
  "LIBERTS_AND_CO_AUTH": "/fe_assets/images/logos/lieber_and_co.png",
  "AFS_AUTH": "/fe_assets/images/logos/afs.png",
}

export const COMPANY_THEME_BACKGROUND = {
  "XML_EDGE": "#07162b",
  "BOI_AGENT": "#07162b",
  "LIBERTS_AND_CO": "#133045",  
  "AFS": "#07162b"
}