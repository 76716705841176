import { Injectable } from "@angular/core";
import { io, Socket } from "socket.io-client";
import { environment } from "../../../environments/environment";
import { SOCKET_EVENT } from "../constants";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root"
})
export class WebsocketService {
  private socket: Socket;
  private isFileGenError = false;
  private fileGenerateMessage: string;
  constructor( public cookieService:CookieService ) {
    // this.initialize();
  }

  public initialize() {
    // const token = localStorage.getItem("x-access-token");
    const token =  this.cookieService.get('x-access-token');
    this.socket = io(environment.apiUrl, {
      reconnection: true,
      multiplex: false,
      extraHeaders: { Authorization: `Bearer ${token}` }
    });
    this.socket.on(SOCKET_EVENT.generateFileError, (error) => {
      this.isFileGenError = true;
      this.fileGenerateMessage = error.err;
    });
    this.socket.auth = { token: token };
  }

  public getSocket() {
    if (!this.socket) { 
      this.initialize();
      return this.getSocket();
    } else return this.socket;
  }
  public getIsFileGenerateError(): boolean {
    return this.isFileGenError;
  }
  public getFileGenerateErrorMessage(): string {
    return this.fileGenerateMessage;
  }

  /////////////////////////
  updateIssuerInfoFormData(formId:any,formData: any): void {
    if (this.socket) { 
      this.socket.emit("updateIssuerInfoFormData",formId, formData);
    } else {
      if (!this.socket) {
        this.initialize();
        if (this.socket) {
          this.socket.emit("updateIssuerInfoFormData",formId, formData);
        } else {
          console.error("Socket is not initialized. Call initialize() first.");
        }
      }
    }
  }

  onFormIssuerInfoDataUpdated(formId:any): Observable<any> {
    this.socket.emit('joinForm', formId);
    return new Observable<any>((observer) => {
      if (this.socket) {
        this.socket.on("updateIssuerInfoFormData", (data: any) => {
          observer.next(data);
        });
      } else {
        console.error("Socket is not initialized. Call initialize() first.");
      }
    });
  }


  updateFormData(formId:any,formData: any): void {
    if (this.socket) { 
      this.socket.emit("updateFormData",formId, formData);
    } else {
      if (!this.socket) {
        this.initialize();
        if (this.socket) {
          this.socket.emit("updateFormData",formId, formData);
        } else {
          console.error("Socket is not initialized. Call initialize() first.");
        }
      }
    }
  }

  onFormDataUpdated(formId:any): Observable<any> {
    this.socket.emit('joinForm', formId);
    return new Observable<any>((observer) => {
      if (this.socket) {
        this.socket.on("updateFormData", (data: any) => {
          observer.next(data);
        });
      } else {
        console.error("Socket is not initialized. Call initialize() first.");
      }
    });
  }

  updateIssuerFormData(formId:any,formData: any): void { 
    if (this.socket) { 
      this.socket.emit("issuerFormData",formId, formData);
    } else {
      if (!this.socket) {
        this.initialize();
        if (this.socket) {
          this.socket.emit("issuerFormData",formId, formData);
        } else {
          console.error("Socket is not initialized. Call initialize() first.");
        }
      }
    }
  }

  onFormDataUpdatedForIssuer(formId:any): Observable<any> {
    this.socket.emit('joinForm', formId);
    return new Observable<any>((observer) => { 
      if (this.socket) {
        this.socket.on("issuerFormData", (data: any) => {
          observer.next(data);
        });
      } else {
        console.error("Socket is not initialized. Call initialize() first.");
      }
    });
  }

  updateSignatureFormData(formId:any,formData: any): void { 
    if (this.socket) { 
      this.socket.emit("signatureFormData",formId, formData);
    } else {
      if (!this.socket) {
        this.initialize();
        if (this.socket) {
          this.socket.emit("signatureFormData",formId, formData);
        } else {
          console.error("Socket is not initialized. Call initialize() first.");
        }
      }
    }
  }

  onFormDataUpdatedForSignature(formId:any): Observable<any> {
    this.socket.emit('joinForm', formId);
    return new Observable<any>((observer) => { 
      if (this.socket) {
        this.socket.on("signatureFormData", (data: any) => {
          observer.next(data);
        });
      } else {
        console.error("Socket is not initialized. Call initialize() first.");
      }
    });
  }

  updateRelatedPersonFormData(formId:any,formData: any): void { 
    if (this.socket) { 
      this.socket.emit("relatedPersonFormData",formId, formData);
    } else {
      if (!this.socket) {
        this.initialize();
        if (this.socket) {
          this.socket.emit("relatedPersonFormData",formId, formData);
        } else {
          console.error("Socket is not initialized. Call initialize() first.");
        }
      }
    }
  }

  onFormDataUpdatedForRelatedPerson(formId:any): Observable<any> {
    this.socket.emit('joinForm', formId);
    return new Observable<any>((observer) => { 
      if (this.socket) {
        this.socket.on("relatedPersonFormData", (data: any) => {
          observer.next(data);
        });
      } else {
        console.error("Socket is not initialized. Call initialize() first.");
      }
    });
  }

  updateSalesCompensationFormData(formId:any,formData: any): void { 
    if (this.socket) { 
      this.socket.emit("salesCompensationFormData",formId, formData);
    } else {
      if (!this.socket) {
        this.initialize();
        if (this.socket) {
          this.socket.emit("salesCompensationFormData",formId, formData);
        } else {
          console.error("Socket is not initialized. Call initialize() first.");
        }
      }
    }
  }

  onFormDataUpdatedForSalesCompensation(formId:any): Observable<any> {
    this.socket.emit('joinForm', formId);
    return new Observable<any>((observer) => { 
      if (this.socket) {
        this.socket.on("salesCompensationFormData", (data: any) => {
          observer.next(data);
        });
      } else {
        console.error("Socket is not initialized. Call initialize() first.");
      }
    });
  }
}
