export const ROUTE_PATHS = {
  ROOT: "",
  DASHBOARD: "dashboard",
  COMPANIES: "companies",
  COMPANY: "company",
  USERS: "users",
  DOCUMENTS: "documents",
  ADD: "add",
  ADDVERSION: "addversion",
  EDIT: "edit",
  imergex: "imergx",
  content: "content",
  documentEdit: "doc-edit",
  rollforward: "rollforward",
  release: "release",
  roleManagement: "role-management",
  featureMapping: "feature-mapping",
  client: "client",
  taxonomy: "taxonomy",
  search: "search",
  xeCompare: "xe-compare",
  history: "history",
  dashboard: "dashboard",
  system: "system",
  sqlQuery: "sql-query",
  formType487Rollforward: "rollforward-487-formtype",
  formTypeXBRLRollforward: "rollforward-xbrl-formtype",
  ditamapTree: "dita-map-tree",
  library: "library",
  filings: "filings",
  submissionForm: "submission-form",
  edgareye: "edgar-eye",
  myalrets: "my-alerts",
  alerts: "alerts",
  today: "today",
  easyview: "easyview",
  validator: "validator",
  easyViewCompanies: "easyviewcompanies",
  validatorCompanies: "validatorcompanies",
  projects: "projects",
  marketforms: "marketforms",
  searchFilings: "search-filings",
  xe_login: "login",
  xe_reset_password: "xe-reset-password",
  cpu_reset_password: "cpu-reset-password",
  securex_reset_password: "securex-reset-password",
  securex_login: "securex",
  cpu_login: "cpu",
  imergix: "imergix",
  imergix_docs: "imgerix-docs",
  imergix_project: "imergix-projects",
  uploadFiling: "upload-filing",
  addFiling: "addFiling",
  testFiling: "testFiling",
  liveFiling: "liveFiling",
  ckeditor: "xeeditor",
  setUserPassword: "set-user-password",
  eVox: "eVox",
  EA_TSR: "EA-Connect",
  EA_TSR_ProofPreview: "proof-preview-for-HTML",
  EA_TSR_PdfPreview: "proof-preview-for-PDF",
  xe_setupauthenticator: "xe-setup-authenticator",
  cpu_setupauthenticator: "cpu-setup-authenticator",
  securex_setupauthenticator: "securex-setup-authenticator",
  EvoxAnalytics: "evox-analytics",
  Stitching: "stitching",
  EA_Forms: "EA-Forms",
  EA_FormD: "EA-FormD",
  EA_FormList: "EA-FormList",
  EA_AddForm: "EA-AddForm",
  EA_EditForm: "EA-EditForm",
  issuer_form: "issuer-form",
  person_form: "person-form",
  compensation_form: "compensation-form",
  FILINGS: "filings",
  EA_FOURTY: "EA-40",
  AUDITLOG: "auditlog",
  PREVIEW_DOCUMENT: "preview-document",
  Boir_filinglist: "Boir-list",
  BOIR: "BOIR",
  Boir_add: "Boir-Add",
  Boir_edit: "Boir-Edit",
  Boir_form: "Boir-Form",
  Boir_dashboard: "Boir-Dashboard",
  CREATE_FORM: "create-form",
  BOIR_EDIT_FORM: "edit-form",
  EA_TSR_BUILDER: "TSR",
  TSR_COMPANIES: "company-list",
  LIST: "projects-list",
  ADD_TSR: "add-tsr",
  EDIT_TSR: "edit-tsr",
  ADD_TEMPLATE: "add-template",
  TSR_REPORT: "tsr-report",
  ADD_REPORT: "add-tsr-report",
  EDIT_REPORT: "edit-tsr-report",
  TSR_SHAREHOLDER: "tsr-reports-list",
  DELETED_COMPANIES: "deleted-companies",
  common: "common",
  Boir_preview:'boir-preview',
  CREATE_BOIR_COMPANY: "create-boir-company",
  FIle_COUNTER: "file-counter",
  ENTITY_LIST: "entitylist",
  BENEFICIALOWNER_COMPANYLIST: "beneficialowner-companylist",
};
